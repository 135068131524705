import React from 'react'
import { Link } from 'react-router-dom'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons"
import {CartContext} from "../../hooks/Context"
import {useQuery, gql} from "@apollo/client"
import "./hamburgers.css"
import "./Header.css"

const CATEGORIES = gql`
  query GetCategories {
    categories {
      data {
      id
        attributes {
          name
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }`

export default function Header(props) {

  const {cart} = React.useContext(CartContext)

  const {loading, error, data} = useQuery(CATEGORIES)
  if (loading) return <></>
  if(error) return <p>Error!!!</p>

  const links = data.categories.data.map((category, index) => {
    return <Link to={`/product-list/${category.id}`} className='header-link' key={index}>{(category.attributes.name).replace("_", " ")}</Link>
  })

  function handleBurgerMenuClick() {
    if (!props.cartOpen) {
      props.setBurgerMenuOpen(prevState => !prevState)
    }
  }

  function handleCartIconBtnClick() {
    if (!props.burgerMenuOpen) {
      props.setCartOpen(prevState => !prevState)
    }
  }
  
  return (
    <nav className='navbar'>
      <button alt="burger menu" onClick={handleBurgerMenuClick} className={props.burgerMenuOpen ? "hamburger hamburger--collapse is-active" :  "hamburger hamburger--collapse"} type="button">
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>  

      <div className='header-link-container'>
        <Link to="/" className='header-link'>Home</Link>
        {links}
      </div>
      
      <Link to="/" className='header-company-name-link-container'>
        <p className='header-company-name'>MUD CREEK <br></br> </p>
        <p className='header-company-name-lower'>WORKSHOP</p>
        <img aria-hidden="true" alt='muddy footprint' className='muddy-footprint-svg' src='/assets/muddy-footprint.svg' />
      </Link>

      <div className='shopping-cart-icon-container'>
        <FontAwesomeIcon className='shopping-cart-icon' alt="shopping cart icon" onClick={handleCartIconBtnClick} icon={faShoppingCart} />
        {
          cart.length > 0 
          ?
            <p onClick={handleCartIconBtnClick} className='shopping-cart-icon-num-badge'>{cart.length}</p>
          :
            <></>
        }
       
      </div>
    </nav>
  )
}
