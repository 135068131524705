import React from 'react'
import "./About.css"

export default function About() {
  return (
    <div className='about'>
        <img className='about-img' alt="wood products made by Mud Creek Workshop, such as cutting boards, cheese boards, pizza cutters, and more. All of these products are sitting on a table outside at a trade show." src='/assets/outdoor-setup.jpg'/>
        <section className='about-content'>
            <h2 className='about-title'>Bringing you the <span className='about-title-span'>best</span>  woodworking products</h2>
            <p className='about-text'>
              Mud Creek Workshop is a small woodworking business based in the Des Moines area. My appreciation for woodworking started with shop classes in high school. I have worked with wood most of my life doing remodeling, home renovation, and carpentry projects. I returned to woodworking about 3 years ago and specialize in handmade wooden items for the kitchen. I have many stock products but also create custom items.
            </p>
        </section>
    </div>
  )
}
