import React from 'react'
import { useEffect } from 'react'
import {useQuery, gql} from "@apollo/client"
import "./ProductListAll.css"
import CategoryCards from "../../components/categoryCards/CategoryCards"
import About from "../../components/about/About"
import ProductCard from '../../components/productCard/ProductCard'

const ALLPRODUCTS = gql`
query GetAllProducts {
    products {
        data {
            id
            attributes {
                title
                description
                price 
                category {
                  data {
                    id
                    attributes {
                        name
                    }
                  }
                }
                price
                productImages {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                image {
                    data {
                        attributes {
                            url
                        }
                    }
                }
            }
        }
    }
}`

export default function ProductListAll() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {loading, error, data} = useQuery(ALLPRODUCTS)
  if (loading) return <p>Loading...</p>
  if(error) return <p>Error!!!</p>

  const productElements = data.products.data.map((product, index) => {
    let extraClass = ""

    if ((index + 1) % 2 === 0) {
      extraClass = "flex-reverse"
    }

    return (
      <ProductCard  
        key={product.id}
        id={product.id}
        categoryId={product.attributes.category.data.id}
        image={product.attributes.image.data.attributes.url}
        name={product.attributes.title}
        description={product.attributes.description}
        price={product.attributes.price}
        extraClass={extraClass}
      />
    )
  })
  
  return (
    <div className='product_list_all'>
      <section className='product_list_all-category-name-container'>
        <h1 className='product_list_all-category-name'>All products</h1>
      </section>

      {
        data.products.data.length === 0 ?
          <div className='empty-category-container'>
          <img className='empty-category-logo' src='/assets/logo.png' alt='logo for Mud Creek Workshop, it is a a house with the name of the company below it, with trees in the background.'/>
          <h2 className='empty-category-title'>Coming soon!</h2>
            <p className='empty-category-text'> We are currently working on getting products ready for this category, because everything is handmade it can take time to ensure all products are up to our high standards. Please check out the other categories for available products!</p>
          </div>
        :
         <></>
      }
      
      {productElements}
      <CategoryCards />
      <About />

    </div>
  )
}