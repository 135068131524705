import {Route, Routes, useLocation} from 'react-router-dom'
import {ApolloClient, InMemoryCache, ApolloProvider} from "@apollo/client"

import Cart from "./components/cart/Cart"
import Header from "./components/header/Header"
import Footer from "./components/footer/Footer"
import { CartContext } from './hooks/Context';

import Home from "./pages/home/Home"
import Checkout from "./pages/checkout/Checkout"
import BurgerMenuModal from './components/burgerMenuModal/BurgerMenuModal'
import ProductDetail from "./pages/productDetail/ProductDetail"
import ProductListAll from './pages/productList/ProductListAll'
import ProductListOneCat from "./pages/productList/ProductListOneCat"
import { useEffect, useState } from 'react'
import "./index.css"
import PurchaseSuccess from './pages/purchaseSuccess/PurchaseSuccess'
import Contact from './pages/contact/Contact'

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  cache: new InMemoryCache()
})


function App() {

  const [cart, setCart] = useState(() => {
    let cartData = JSON.parse(localStorage.getItem("cart"))
    if (cartData) {
      return cartData
    }else {
      return []
    }
  })

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)
  const [cartOpen, setCartOpen] = useState(false)

  const location = useLocation()

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart))
  }, [cart])

  return (
    <CartContext.Provider value={{cart, setCart}}>
      <ApolloProvider client={client}>
        <div className="app">

        <Header setBurgerMenuOpen={setBurgerMenuOpen} setCartOpen={setCartOpen} burgerMenuOpen={burgerMenuOpen} cartOpen={cartOpen}/>
        <div className='header-space'></div>
        {burgerMenuOpen && !cartOpen ?
            <>
              <BurgerMenuModal setBurgerMenuOpen={setBurgerMenuOpen} />
              <div aria-hidden="true" className='burger_menu_modal-page-cover' ></div>
            </>
          :
            <></>
        }

        {cartOpen && !burgerMenuOpen && location.pathname !== "/checkout"  ? 
            <>
              <Cart 
                setCartOpen={setCartOpen} 
              />
              <div aria-hidden="true" className='burger_menu_modal-page-cover' ></div>
            </>
          :
            <></>
        }

        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/product-detail/:id" element={<ProductDetail />}/>
          <Route path="/product-list" element={<ProductListAll />}/>
          <Route path="/product-list/:id" element={<ProductListOneCat />}/>
          <Route path="/checkout" element={<Checkout />}/>
          <Route path="/purchase-success" element={<PurchaseSuccess />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>

        <Footer />

        </div>
      </ApolloProvider>
    </CartContext.Provider>
  );
}

export default App;
