import React from 'react'
import { Link } from 'react-router-dom'
import "./ProductCardSmall.css"

export default function ProductCardSmall(props) {

    const {id, name, image, categoryId} = props

  return (
    <div className='product_card_small'>
      <div className='product_card_small-img-container'>
        <img className='product_card_small-img' alt={name} src={`${image}`}/>
      </div>
      <h2 className='product_card_small-name'>{name}</h2>
      <Link className='product_card_small-link' state={{categoryId: categoryId}} to={`/product-detail/${id}`}>see product</Link>
    </div>
  )
}
