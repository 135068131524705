import React, { useEffect } from 'react'
import {Link} from "react-router-dom"
import {CartContext} from "../../hooks/Context"
import "./Cart.css"

export default function Cart(props) {

  const {cart, setCart} = React.useContext(CartContext)
  let total = 0

  useEffect(() => {
    function checkForZeroCountProducts() {
      for (let i = 0; i < cart.length; i++) {
        if(cart[i].quantity.count === 0) {
          let cartContainer = cart.slice()
          cartContainer.splice(i, 1)
          setCart(cartContainer)
        }
      }
    }
    checkForZeroCountProducts()
  }, [cart])
  
  const currentCartItems = cart.map((item, index) => {
    let extrasPrice = 0
    for (const [key, value] of Object.entries(item.choices)) {
      extrasPrice += value.price ? value.price : 0
    }

    calculateTotal(item.quantity.count, item.product.attributes.price, extrasPrice)
    
    return (
      <div className='cart-item-card' key={index}>

        <div className='cart-item-card-content'>
          <div className='cart-item-img-container'>
            <img className='cart-item-img' alt={item.product.attributes.title} src={`${item.productImageUrl}`}/>
          </div>
          <div className='cart-item-text-container'>
            <p className='cart-item-name'>{item.product.attributes.title} </p>
            <p className='cart-item-price'>{item.choices.woodTypes.name} {item.choices.sizes.name} {item.choices.extraChoices.name === "None" ? "" : item.choices.extraChoices.name}</p>
            <p className='cart-item-price'>{(item.product.attributes.price + extrasPrice).toLocaleString("en-US", {style:"currency", currency:"USD"})}</p>
          </div>
        </div>

        <div className='cart-item-count-btn-container' id={item.quantity.cartItemId} choices="yes">
          <button className='minus-btn-cart' onClick={handleMinusBtnClick}>-</button>
          <p className='cart-item-count'>{item.quantity.count}</p>
          <button className='plus-btn-cart' onClick={handlePlusBtnClick}>+</button>
        </div>

      </div>
    )
  })

  function handleRemoveAllClick() {
    setCart([])
  }

  function calculateTotal(quantity, price, extrasPrice) {
    let currentItemTotal = (quantity * price) + (extrasPrice * quantity)
    total += currentItemTotal
  }

  function handleMinusBtnClick(event) {
    let currentId = event.target.parentElement.id
    setCart(prevState => prevState.map((item) => {
      if (item.quantity.cartItemId === currentId) {
        return {...item, quantity: {...item.quantity, count: item.quantity.count - 1}}
      }else {
        return item
      }
    }))
  }

  function handlePlusBtnClick(event) {
    let currentId = event.target.parentElement.id
    setCart(prevState => prevState.map((item) => {
      if (item.quantity.cartItemId === currentId) {
        return {...item, quantity: {...item.quantity, count: item.quantity.count + 1}}
      }else {
        return item
      }
    }))
  }

  

  function handleCheckoutBtnClick() {
    closeCartModal()
  }
  
  function closeCartModal() {
    props.setCartOpen(false)
  }
  
  return (
    <div className='cart'>

      <div className='cart-title-container'>
        <p className='cart-title'>cart ({cart.length})</p>
        <button onClick={handleRemoveAllClick} className='cart-remove-all-btn'> Remove all</button>
      </div>

      {currentCartItems}

      <div className='cart-total-container'>
        <p className='cart-total-title'>Total</p>
        <p className='cart-total'>{(total).toLocaleString("en-US", {style:"currency", currency:"USD"})}</p>
      </div>

      <Link to="/checkout"><button onClick={handleCheckoutBtnClick} className='cart-checkout-btn' disabled={cart.length > 0 ? false : true}> Checkout</button></Link>
      
    </div>
  )
}
