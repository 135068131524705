import React from 'react'
import About from '../../components/about/About'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import "./Home.css"
import CategoryCards from '../../components/categoryCards/CategoryCards'

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return ( 
    <div className='home_page'>
      <div className='home_page-cover-img-container'>
        <img className='home_page-cover-img' src="/assets/product-overview2.jpg" alt="wood products made by Mud Creek Workshop, such as cutting boards, cheese boards, pizza cutters, and more."/>
        <div className='home_page-logo-container'>
          <img className='home_page-logo' src='/assets/logo.png' alt='logo for Mud Creek Workshop, it is a a house with the name of the company below it, with trees in the background.'/>
        </div>
        <Link to={"/product-list"} className='home_page-see-products-btn'> See Products </Link>
      </div>

      <CategoryCards />

      <About />
    </div>
  )
}
