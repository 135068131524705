import React from 'react'
import {Link} from "react-router-dom"
import {useQuery, gql} from "@apollo/client"
import "./Footer.css"

const CATEGORIES = gql`
  query GetCategories {
    categories {
      data {
      id
        attributes {
          name
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default function Footer() {

  const {loading, error, data} = useQuery(CATEGORIES)
  if (loading) return <></>
  if(error) return <p>Error!!!</p>

  const links = data.categories.data.map((category, index) => {
    return <Link to={`/product-list/${category.id}`} className='footer-link' key={index}>{(category.attributes.name).replace("_", " ")}</Link>
  })

  return (
    <div className='footer'>
      <div className='link-logo-container'>
        <Link to="/" className='footer-company-name-link'>
          <div className='footer-company-name-container'>
            <p className='footer-company-name'>MUD CREEK <br></br> </p>
            <p className='footer-company-name-lower'>WORKSHOP</p>
          </div>
        </Link>
    
        <div className='footer-links-container-desktop'>
          <Link to="/" className='footer-link'> Home </Link>
          <Link to="/contact" className='footer-link'>Contact Us</Link>
          {links}
        </div>
      </div>
      
      <div className='footer-links-container'>
        <Link to="/" className='footer-link'> Home </Link>
        <Link to="/contact" className='footer-link'>Contact Us</Link>
        {links}
      </div>

      <p className='footer-about-text'>Mud Creek Workshop is a Family owned business that offers various handmade, high quality woodworking products. We appreciate your support!</p>
      <p className='footer-copyright-text'>© 2024 Mud Creek Workshop - All Rights Reserved</p>
    </div>
  )
}
