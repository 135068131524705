import React, {useEffect} from 'react'
import { useJsonData } from 'herotofu-react'
import { useNavigate } from "react-router-dom";
import {useQuery, gql} from "@apollo/client"
import {CartContext} from "../../hooks/Context"
import { Link } from 'react-router-dom';
import "./PurchaseSuccess.css"

const params = new URLSearchParams(window.location.search)
const sessionId = params.get("session_id")

const CURRENTORDER = gql `
   query {
  	orders(filters:{stripeSessionId: {contains: "${sessionId}"}}) {
			data {
        id
        attributes {
          	userName
            shippingAddress
            billingAddress
            products
            contactInfo
				}
			}
  	}
 }`

export default function PurchaseSuccess() {

  localStorage.clear()
  const navigate = useNavigate();
  const {cart, setCart} = React.useContext(CartContext)
  const { dataState, sendData } = useJsonData('https://public.herotofu.com/v1/0f4d7a20-477b-11ef-9c40-81ddfd46a512');
  const {loading, error, data} = useQuery(CURRENTORDER)

  function createProductString() {
    let productString = ""
    for (let i = 0; i < data.orders.data[0].attributes.products.length; i++) {
      let choicesString = ""
      for(let j = 0; j < data.orders.data[0].attributes.products[i].choices.length; j++) {
        if (data.orders.data[0].attributes.products[i].choices[j]) {
          choicesString += ` ${data.orders.data[0].attributes.products[i].choices[j]},`
        }
      }
      productString += ` Product ${i + 1} of ${data.orders.data[0].attributes.products.length}: ProductId- ${data.orders.data[0].attributes.products[i].id} ProductCount- ${data.orders.data[0].attributes.products[i].count} Extras: ${choicesString} |||`
    }
    return productString
  }

  const onSubmitCallback = ({ status, data }) => {};

  const handleSendingFormData = (name, email, phoneNumber, street1, street2, country, city, state, zipCode, products) => {
    sendData(onSubmitCallback, { name, email, phoneNumber, street1, street2, country, city, state, zipCode, products});
  }
  
  useEffect(() => {

    if (loading === false) {
      handleSendingFormData(
        data.orders.data[0].attributes.userName, 
        data.orders.data[0].attributes.contactInfo.email, 
        data.orders.data[0].attributes.contactInfo.phoneNumber,
        data.orders.data[0].attributes.shippingAddress.street1,
        data.orders.data[0].attributes.shippingAddress.street2,
        data.orders.data[0].attributes.shippingAddress.country,
        data.orders.data[0].attributes.shippingAddress.city,
        data.orders.data[0].attributes.shippingAddress.state,
        data.orders.data[0].attributes.shippingAddress.zipCode,
        createProductString()
      )
    }
    
    setTimeout(() => {
      navigate("/")
    }, 20000)

  }, [loading])

  useEffect(() => {
    setCart([])
  }, [])
  
  if (loading) return <></>
  if(error) return <p>Error!!!</p>

  return (
    <div className='success_page'>
      <img className='success_page-logo' src='/assets/logo.png' alt='logo for Mud Creek Workshop, it is a a house with the name of the company below it, with trees in the background.'/>
      <h1 className='success_page-title'>Thank you for your order:<br></br> {data.orders.data[0].attributes.userName}!</h1>
      <p className='success_page-blurb'>We appreciate your support of small businesses' </p>
      <Link to="/"><button className='success_page-btn'>Continue Shopping</button></Link>
    </div>
  )
}
