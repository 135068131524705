import React from 'react'
import CategoryCard from '../categoryCard/CategoryCard'
import {useQuery, gql} from "@apollo/client"
import "./CategoryCards.css"

const CATEGORIES = gql`
  query GetCategories {
    categories {
      data {
        id
        attributes {
          name
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default function CategoryCards(props) {
    const {loading, error, data} = useQuery(CATEGORIES)

    if (loading) return <></>
    if(error) return <p>Error!!!</p>

    const categoryCards = data.categories.data.map((category, index) => {
        return <CategoryCard categoryID={category.id} key={index} name={category.attributes.name} image={category.attributes.image.data.attributes.url} setBurgerMenuOpen={props.setBurgerMenuOpen} />
    })

    return (
        <div className='category_cards-container'>
            {categoryCards}
        </div>
    )
}
