import React from 'react'
import { Link } from 'react-router-dom'
import "./ProductCard.css"

export default function ProductCard(props) {
  const {id, categoryId, image, name, description, extraClass} = props

  return (
    <div className={`product_card ${extraClass}`}>
      <div className='product_card-img-container'>
        <img className='product_card-img' alt='{name}' src={`${image}`} />
      </div>
      <div className='product_card-content-container'>
        <h2 className='product_card-name'>{name}</h2>
        <p className='product_card-desc'>{description}</p>
        <Link className='product_card-link' to={`/product-detail/${id}`} state={{categoryId: categoryId}} >See Product</Link>
      </div>
    </div>
  )
} 