import React from 'react'
import {CartContext} from "../../hooks/Context"
import { useState } from 'react'
import BillingAddressForm from '../../components/BillingAddressForm/BillingAddressForm'
import {loadStripe} from "@stripe/stripe-js"
import "./Checkout.css"
import isEqual from 'lodash.isequal';

const stripePromise = loadStripe(`${process.env.STRIPE_PUBLISHABLE_KEY}`)

export default function Checkout() {

  const {cart} = React.useContext(CartContext)
  const [shippingAddressSame, setShippingAddressSame] = useState(true)

  const [billingAddress, setBillingAddress] = useState({
    firstName: "",
    lastName: "",
    country: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    zipCode: ""
  })

  const [shippingAddress, setShippingAddress] = useState({
     firstName: "",
     lastName: "",
     country: "",
     street1: "",
     street2: "",
     city: "",
     state: "",
     zipCode: ""
  })

  const [contactInfo, setContactInfo] = useState({
    email: "",
    phoneNumber: ""
  })

  const handleFormSubmit = async (event) => {
    event.preventDefault()
    let country = (billingAddress.country).toLowerCase()
    if (country === "usa" || country === "u.s.a" || country === "united states" || country === "united states of america" ) {

    }else {
      alert("We apologize, shipping is not currently available for addresses outside of the United States.")
      return
    }

    if (shippingAddressSame) {
      makePayment(billingAddress)
    }else {
      makePayment(shippingAddress)
    }
  }

  async function calculateTotalChoiceCost(cartItem) {
    let totalExtraCost = 0
    const data = await getProductData(cartItem.product.id)
  
    if (await data.attributes.woodTypes) {
      for (const choice of data.attributes.woodTypes) {
        isEqual(choice, cartItem.choices.woodTypes) ? totalExtraCost += choice.price : totalExtraCost += 0
      }
    }

    if (await data.attributes.sizes) {
      for (const choice of data.attributes.sizes) {
        isEqual(choice, cartItem.choices.sizes) ? totalExtraCost += choice.price : totalExtraCost += 0
      }
    }

    if (await data.attributes.extraChoices) {
      for (const choice of data.attributes.extraChoices) {
        isEqual(choice, cartItem.choices.extraChoices) ? totalExtraCost += choice.price : totalExtraCost += 0
      }
    }

    return totalExtraCost
  }

  async function getProductData(productId) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/${productId}`)
    const data = await response.json()
    return data.data
  }

  function getChoiceArray(cartItem) {
    let chosenChoices = []
    for (const [key, value] of Object.entries(cartItem.choices)) {
      chosenChoices.push(value.buttonText ? value.buttonText : "")
    }
    return chosenChoices
  }

  async function makePayment(shippingAddress) {

    let totalChoiceCost = []
    for (const product of cart) {
      totalChoiceCost.push(await calculateTotalChoiceCost(product))
    }

    const stripe = await stripePromise
    const name = `${shippingAddress.firstName} ${shippingAddress.lastName}`
    const requestBody = {
      data: {
        products: cart.map((item, index) => {
          let choices = getChoiceArray(item)
          let choiceCostAmount = totalChoiceCost[index]
          return ({
            id: item.quantity.productId,
            count: item.quantity.count,
            choiceCost: choiceCostAmount,
            choices: choices,
            image: item.productImageUrl
          })
        }),
        userName: name,
        email: contactInfo.email,
        shippingAddress: shippingAddress,
        billingAddress: billingAddress,
        contactInfo: contactInfo
      }
    }

    try  {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/orders`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {"Content-Type": "application/json"}
      })
  
      const session = await response.json()
      window.location.href = await session.url

    }catch(error) {
      alert("There was an error attempting to continue to the next step. Please ensure that all form fields are correct and valid, then try again.")
    }
    
  }

  return (
    <div className='checkout-container'>
        <h1 className='checkout-title'>Checkout</h1>
          <BillingAddressForm 
            shippingAddressSame={shippingAddressSame}
            setShippingAddressSame={setShippingAddressSame}
            billingAddress={billingAddress}
            setBillingAddress={setBillingAddress}
            shippingAddress={shippingAddress}
            setShippingAddress={setShippingAddress}
            contactInfo={contactInfo}
            setContactInfo={setContactInfo}
            handleFormSubmit={handleFormSubmit}
          />
    </div>
  )
}
