import React from 'react'
import "./Contact.css"

export default function Contact() {
  return (
    <div className="contact">
        <div className='contact-title-container'>
            <h1 className='contact-title'>Contact Form:</h1>
            <p className='contact-instructions'>Have a question or concern? A request for a specially made item? Fill out the form below with as much detail as possible, and we will get back to you as soon as we can.</p>
        </div>
    
        <form className='contact-form' action="https://public.herotofu.com/v1/e589a4c0-477a-11ef-a0b7-6772a76ef8ef" method="post" acceptCharset="UTF-8">
            <div className='contact-form-input-container'>
                <label className='contact-form-input-label'>First Name</label>
                <input name="FirstName" className='contact-form-input' type='text' placeholder='James' maxLength="75" required />
            </div>

            <div className='contact-form-input-container'>
                <label className='contact-form-input-label'>Last Name</label>
                <input name="LastName" className='contact-form-input' placeholder='Smith' maxLength="75" type='text' required />
            </div>

            <div className='contact-form-input-container'>
                <label className='contact-form-input-label'>Email Address</label>
                <input name="EmailAddress" className='contact-form-input' type='text' placeholder='jamessmith@gmail.com' maxLength="75"  required />
            </div>

            <div className='contact-form-input-container'>
                <label className='contact-form-input-label'>Phone Number</label>
                <input name="Phone Number" className='contact-form-input' type='tel' maxLength='12' placeholder='555-867-8559' required />
            </div>

            <div className='contact-form-input-container'>
                <label className='contact-form-input-label'>Additional information</label>
                <textarea name="AdditionalInfo" className='contact-form-input-textarea' type='text' maxLength='2000' placeholder='What can we help you with?' required />
            </div>

            <input aria-hidden="true" className='gotcha' type="text" name="_gotcha" tabIndex="-1" autoComplete="off" />
            
            <button className='contact-form-submit-btn'> Submit form</button>
        </form>
    </div>
  )
}
