import "./CategoryCard.css"
import { Link } from "react-router-dom"

export default function CategoryCard(props) {

    const {name, image, categoryID} = props

    function handleLinkClick() {
        if (props.setBurgerMenuOpen) {
            props.setBurgerMenuOpen(false)
        }
    }

    return (
        <div className="category_card">
            <img className="category_card-img" alt={`${name.replace("_", " ")} product`} src={`${image}`} />
            <h2 className="category_card-name"> {name.replace("_", " ")} </h2>
            <Link onClick={handleLinkClick} to={`/product-list/${categoryID}`} className="category_card-shop-btn">{`shop `}<span className="category_card-orange-arrow">{`>`}</span></Link>
        </div>
    )
}