import React from 'react'
import {CartContext} from "../../hooks/Context"
import "./BillingAddressForm.css"

export default function ShippingAddressForm(props) {

  const {cart} = React.useContext(CartContext)
  let total = 0

  const {
    shippingAddressSame, 
    setShippingAddressSame,
    billingAddress,
    setBillingAddress,
    shippingAddress,
    setShippingAddress,
    contactInfo,
    setContactInfo,
    handleFormSubmit
  } = props

  function calculateTotal(quantity, price, totalExtraCost) {
    let currentItemTotal = (quantity * price) + (totalExtraCost * quantity)
    total += currentItemTotal
  }

  const productElements = cart.map((item, index) => {
    
    let totalExtraCost = 0
    for (const [key, value] of Object.entries(item.choices)) {
      totalExtraCost += value.price ? value.price : 0
    }

    calculateTotal(item.quantity.count, item.product.attributes.price, totalExtraCost)

    return (
      <div className='checkout-item-card' key={index}>
        <div className='checkout-item-card-content'>
          <div className='checkout-img-container'>
            <img className='checkout-item-img' alt={item.product.attributes.title} src={`${item.productImageUrl}`}/>
          </div>
          <div className='checkout-item-text-container'>
            <p className='checkout-item-name'>{item.product.attributes.title} </p>
            <p className='checkout-item-price'> {item.choices.woodTypes.name} {item.choices.sizes.name} {item.choices.extraChoices.name === "None" ? "" : item.choices.extraChoices.name}</p>
            <p className='checkout-item-price'>{((item.product.attributes.price) + totalExtraCost).toLocaleString("en-US", {style:"currency", currency:"USD"})}</p>
          </div>
        </div>

        <p className='checkout-item-count'>x{item.quantity.count}</p>
      </div>
    )
  })

  return (
    <form className='checkout-form-container' onSubmit={handleFormSubmit} action="https://public.herotofu.com/v1/48f4ee50-5575-11ee-99c9-b9cd803366c9" method="post" acceptCharset="UTF-8">
      <div className='shipping-billing-container'>
        <p className='section-title'>Billing Details</p>
        <div className='billing-container'>
          <div className='input-container'>
            <label className='input-label'>First Name</label>
            <input name="Name" id="name" className="input" type='text' placeholder='James' maxLength="75" required value={billingAddress.firstName} onChange={(e) =>{
                if(e.target.value.match("^[a-zA-Z ]*$") != null){
                  setBillingAddress(prevState => ({...prevState, firstName: e.target.value}) )
                }
              }}
            />
          </div>

          <div className='input-container'>
            <label className='input-label'>Last Name</label>
            <input className="input" type='text' placeholder='Smith' maxLength="75" required value={billingAddress.lastName} onChange={(e) => {
                if(e.target.value.match("^[a-zA-Z ]*$") != null){
                  setBillingAddress(prevState => ({...prevState, lastName: e.target.value}) )
                }
              }}
            />
          </div>

          <div className='input-container'>
            <label className='input-label'>Street Address</label>
            <input className="input" type='text' placeholder='1137 Williams Avenue' maxLength="75" required value={billingAddress.street1} onChange={(e) => setBillingAddress(prevState => ({...prevState, street1: e.target.value}) )}/>
          </div>

          <div className='input-container'>
            <label className='input-label'>Street Address 2 (optional)</label>
            <input className="input" type='text' placeholder='Unit 8' maxLength="75" value={billingAddress.street2} onChange={(e) => setBillingAddress(prevState => ({...prevState, street2: e.target.value}) )}/>
          </div>

          <div className='input-container'>
            <label className='input-label'>City</label>
            <input className="input" type='text' placeholder='Chicago' maxLength="75" required value={billingAddress.city} onChange={(e) => {
                if(e.target.value.match("^[a-zA-Z ]*$") != null){
                  setBillingAddress(prevState => ({...prevState, city: e.target.value}) )
                }
              }}
            />
          </div>

          <div className='input-container'>
            <label className='input-label'>State</label>
            <input className="input" type='text' placeholder='Illinois' maxLength="75" required value={billingAddress.state} onChange={(e) => {
                if(e.target.value.match("^[a-zA-Z ]*$") != null){
                  setBillingAddress(prevState => ({...prevState, state: e.target.value}) )
                }
              }}
            />
          </div>

          <div className='input-container'>
            <label className='input-label'>Country</label>
            <input className="input" type='text' placeholder='United States' maxLength="75" required value={billingAddress.country} onChange={(e) => {
                if(e.target.value.match("^[a-zA-Z ]*$") != null){
                  setBillingAddress(prevState => ({...prevState, country: e.target.value}) )
                }
              }}
            />
          </div>

          <div className='input-container'>
            <label className='input-label'>Zip Code</label>
            <input className="input" type='text' placeholder='50345' maxLength="5" required value={billingAddress.zipCode} onChange={(e) => {
              if(e.target.value.match("^[0-9]*$") != null){
                setBillingAddress(prevState => ({...prevState, zipCode: e.target.value}) )
                }
              }}
            />
          </div>
          
          <div className='container'>
            <p className='section-title'>Contact Details</p>
            <div className='contact-details'>
              <div className='input-container'>
                <label htmlFor="email" className='input-label'>Email</label>
                <input className="input" type='text' name="email" placeholder='jamessmith@gmail.com' maxLength="75" required value={contactInfo.email} onChange={(e) => setContactInfo(prevState => ({...prevState, email: e.target.value}) )}/>
              </div>

              <div className='input-container'>
                <label htmlFor="phone" className='input-label'>Phone Number</label>
                <input className="input" type='tel'name="phone" maxLength='12' placeholder='555-867-8559' required value={contactInfo.phoneNumber} onChange={(e) => { 
                    if(e.target.value.match("^[0-9- ]*$") != null){
                      setContactInfo(prevState => ({...prevState, phoneNumber: e.target.value}) )
                    }
                  }}
                />
              
              </div>
            </div>

            <div className='checkbox-container' >
            <label htmlFor="sameshipping" className='checkbox-label'> Same for Shipping Address</label>
            <input 
              className='checkbox-input'
              name='sameshipping'
              type='checkbox' 
              defaultChecked 
              onChange={() => setShippingAddressSame(prevState => !prevState)}
            />
          </div>
          </div>
      
        </div>
        
        {
          !shippingAddressSame && 
          <>
             <p className='section-title'>Shipping Address</p>
            <div className='shipping-container'>

              
                <div className='input-container'>
                  <label className='input-label'>First Name</label>
                  <input className="input" type='text' placeholder='James' maxLength="75" required value={shippingAddress.firstName} onChange={(e) =>{
                      if(e.target.value.match("^[a-zA-Z ]*$") != null){
                        setShippingAddress(prevState => ({...prevState, firstName: e.target.value}) )
                      }
                    }}
                  />
                </div>

                <div className='input-container'>
                  <label className='input-label'>Last Name</label>
                  <input className="input" type='text' placeholder='Smith' maxLength="75" required value={shippingAddress.lastName} onChange={(e) => {
                      if(e.target.value.match("^[a-zA-Z ]*$") != null){
                        setShippingAddress(prevState => ({...prevState, lastName: e.target.value}) )
                      }
                    }}
                  />
                </div>

                <div className='input-container'>
                  <label className='input-label'>Street Address</label>
                  <input className="input" type='text' placeholder='1137 Williams Avenue' maxLength="75" required value={shippingAddress.street1} onChange={(e) => setShippingAddress(prevState => ({...prevState, street1: e.target.value}) )}/>
                </div>

                <div className='input-container'>
                  <label className='input-label'>Street Address 2 (optional)</label>
                  <input className="input" type='text' placeholder='Unit 8' maxLength="75" value={shippingAddress.street2} onChange={(e) => setShippingAddress(prevState => ({...prevState, street2: e.target.value}) )}/>
                </div>

                <div className='input-container'>
                  <label className='input-label'>City</label>
                  <input className="input" type='text' placeholder='Chicago' maxLength="75" required value={shippingAddress.city} onChange={(e) => {
                      if(e.target.value.match("^[a-zA-Z ]*$") != null){
                        setShippingAddress(prevState => ({...prevState, city: e.target.value}) )
                      }
                    }}
                  />
                </div>

                <div className='input-container'>
                  <label className='input-label'>State</label>
                  <input className="input" type='text' placeholder='Illinois' maxLength="75" required value={shippingAddress.state} onChange={(e) => {
                      if(e.target.value.match("^[a-zA-Z ]*$") != null){
                        setShippingAddress(prevState => ({...prevState, state: e.target.value}) )
                      }
                    }}
                  />
                </div>

                <div className='input-container'>
                  <label className='input-label'>Country</label>
                  <input className="input" type='text' placeholder='United States' maxLength="75" required value={shippingAddress.country} onChange={(e) => {
                      if(e.target.value.match("^[a-zA-Z ]*$") != null){
                        setShippingAddress(prevState => ({...prevState, country: e.target.value}) )
                      }
                    }}
                  />
                </div>

                <div className='input-container'>
                  <label className='input-label'>Zip Code</label>
                  <input className="input" type='text' placeholder='50345' maxLength="5" required value={shippingAddress.zipCode} onChange={(e) => {
                    if(e.target.value.match("^[0-9]*$") != null){
                      setShippingAddress(prevState => ({...prevState, zipCode: e.target.value}) )
                      }
                    }}
                  />
                </div>
            </div>
          </>
        }
      </div>

      <div className="summary-container">

        <h2 className="summary-title">Summary</h2>
        {productElements}

        <div className="total-breakdown-container">

          <div className="total-breakdown-inner-container">
            <p className="total-name">total</p>
            <p className="total-amount">{(total).toLocaleString("en-US", {style:"currency", currency:"USD"})}</p>
          </div>

          <div className="total-breakdown-inner-container">
            <p className="total-name">shipping</p>
            <p className="total-amount">FREE</p>
          </div>

          <div className="total-breakdown-inner-container">
            <p className="total-name">tax</p>
            <p className="total-amount">
              {
                (billingAddress.state).toLowerCase() === "iowa" || (billingAddress.state).toLowerCase() === "ia" ? 
                (Math.round((total * .07) * 100) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) : 
                (0).toLocaleString("en-US", {style:"currency", currency:"USD"})
              }
            </p>
          </div>

          <div className="total-breakdown-inner-container grand-total-container">
            <p className="total-name">grand total</p>
            <p className="total-amount grand-total-amount">
              {
                (billingAddress.state).toLowerCase() === "iowa" || (billingAddress.state).toLowerCase() === "ia" ? 
                (Math.round((total * 1.07) * 100) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) : 
                (Math.round((total * 1) * 100) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})
              }
            </p>
          </div>

        </div>

      </div>
      <button className='checkout-pay-btn' type="submit">Continue and Pay</button>

    </form>
  )
}
