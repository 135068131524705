import React from 'react'
import CategoryCards from '../categoryCards/CategoryCards'
import "./BurgerMenuModal.css"

export default function BurgerMenuModal(props) {

  return (
    <div className='burger_menu'>
        <div className='burger_menu_modal'>
            <CategoryCards setBurgerMenuOpen={props.setBurgerMenuOpen}  />
        </div>      
    </div>
  )
}
