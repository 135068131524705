import React, { useState } from 'react'
import "./ProductDetail.css"
import About from "../../components/about/About"
import { useEffect } from 'react'
import {useQuery, gql} from "@apollo/client"
import { useParams, useLocation } from 'react-router-dom'
import {CartContext} from "../../hooks/Context"
import ProductCardSmall from '../../components/productCardSmall/ProductCardSmall'
import {v4 as uuid} from 'uuid'
import isEqual from 'lodash.isequal';

const TWOQUERIES = gql`
  query twoQueries($p1: ID!, $p2: ID!) {
   
    product(id: $p1) {
      data {
          id
          attributes {
            title
            description
            price
            width
            length
            thickness
            madeToOrder
            woodTypes
            sizes
            extraChoices
            image {
              data {
                attributes {
                  url
                }
              }
            }
            productImages {
              data {
                attributes {
                  url
                }
              }
            }
          }
      }
    }
  
    category(id: $p2) {
      data { 
        attributes {
          name
          products {
            data {
              id
              attributes {
                title
                description
                price 
                category {
                  data {
                    id
                  }
                }
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function ProductDetail(props) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {id} = useParams()
  const location = useLocation()
  const catId = location.state.categoryId 
  const {cart, setCart} = React.useContext(CartContext)
  const [itemCount, setItemCount] = useState(1)
  const [currentUrl, setCurrentUrl] = useState([])
  const [productChoicesCost, setProductChoicesCost] = useState({
    woodTypes: 0,
    sizes: 0,
    extraChoices: 0
  })
  const [productChoices, setProductChoices] = useState({
    woodTypes: {},
    sizes: {},
    extraChoices: {}
  })

  const {loading, error, data} = useQuery(TWOQUERIES, {
    variables: {
      p1: id, 
      p2: catId
    }, 
  })

  useEffect(() => {
    if (data) {
      setCurrentUrl(data.product.data.attributes.image.data.attributes.url)
    }
  }, [data])

  if (loading) return <></>
  if(error) return <p>Error!!!</p>
  
  const relatedProductsData = data.category.data.attributes.products.data.filter((item) => item.id !== id)

  //// All of the products in the category other than the current one
  const relatedProductElements = relatedProductsData.map((product) => {
    return (
      <ProductCardSmall 
        key={product.id}
        id={product.id}
        categoryId={catId}
        name={product.attributes.title}
        image={product.attributes.image.data.attributes.url}
      />
    )
  })

  function handlePlusBtnClick() {
    if (data.product.data.attributes.madeToOrder) {
      if (itemCount >= 3) {
        return
      }else {
        setItemCount(prevState => prevState + 1)
      }
    }else {
      setItemCount(prevState => prevState + 1)
    }
  }

  function handleMinusBtnClick() {
    if (itemCount > 1) {
      setItemCount(prevState => prevState - 1)
    }
  }

  //// Sets the productChoicesCost state depending on what choice is clicked
  function setChoiceCostData(event) {

    const choiceCategoryName = event.target.name
    let currentCategoryData
    let currentChoiceData
    let price = 0

    if (choiceCategoryName === "woodTypes") {
      setCurrentUrl(data.product.data.attributes.productImages.data[event.target.classList[0]].attributes.url)
      currentCategoryData = data.product.data.attributes.woodTypes
    }else if (choiceCategoryName === "sizes") {
      currentCategoryData = data.product.data.attributes.sizes
    }else if (choiceCategoryName === "extraChoices") {
      currentCategoryData = data.product.data.attributes.extraChoices
    }

    for (const choice of currentCategoryData) {
      if (choice.name === event.target.value) {
        price = choice.price
        currentChoiceData = choice
      }
    }

    setProductChoicesCost((prevState) => {
      return {
        ...prevState,
        [choiceCategoryName]: price
      }
    })

    updateExtraDetails(choiceCategoryName, currentChoiceData)
  }

  ///// Updates the productChoices state with the current selected choice's data
  function updateExtraDetails(name, data) {
    setProductChoices((prevState) => {
      return {
        ...prevState,
        [name]: data
      }
    })
  }

  //// Sums all of the prices from the productChoicesCost
  function getTotalChoiceCost(object) {
    
    return Object.values(object).reduce((a, b) => a + b, 0)
  }

  //// Renders the product choice buttons
  function renderChoices(choices, name) {

    const choiceElements = choices.map((item, index) => {
      return (
        <div className='choice' key={index}>
          <input onChange={setChoiceCostData} required value={item.name} className={`${index} choice-radio-btn`} name={name} id={item.name} type='radio' />
          <label className='choice-radio-btn-label' htmlFor={item.name}>
            {item.buttonText}
          </label>
        </div>
        
      )
    })
    return choiceElements
  }

  ////// Adds a new item to the cart, will add to the quantity of the item if it is already in the cart (checking for if the choices are the same as well)
  function handleAddToCartBtnClick(event) {
    event.preventDefault()

    if (!cart.length) {
      setCart (prevState => [...prevState, {product: data.product.data, productImageUrl: currentUrl, quantity: {productId: data.product.data.id, cartItemId: uuid(), count: itemCount}, choices: productChoices}])
      return
    }

    /// Checks to see if the item already is in the cart, and updates it if it finds one that matches
    for (let i = 0; i < cart.length; i++) {
      if(cart[i].product.id === data.product.data.id && isEqual(cart[i].choices, productChoices)) {
        setCart(prevState => prevState.map((item) => {
           if (item.product.id === data.product.data.id && isEqual(item.choices, productChoices)) {
            return {...item, quantity: {...item.quantity, count: item.quantity.count + itemCount}}
           }else {
            return item
           }
        }))
        return
      }
    }

    /// Adds a brand new item to the cart if the item is not already found
    setCart (prevState => [...prevState, {product: data.product.data, productImageUrl: currentUrl, quantity: {productId: data.product.data.id, cartItemId: uuid(), count: itemCount}, choices: productChoices}])
  
  }
    
  return (
    <div className='product_detail'>

      <div className='product_detail-flex-container'>
        <div className='product_detail-img-container'>
          {
            data.product.data.attributes.productImages.data.length ?
              <img className='product_detail-img' alt={data.product.data.attributes.title} src={`${currentUrl.length ? currentUrl : data.product.data.attributes.productImages.data[0].attributes.url}`}/>
            :
              <img className='product_detail-img' alt={data.product.data.attributes.title} src={`${data.product.data.attributes.image.data.attributes.url}`}/>

          }
        </div>

        <form className='product_detail-form' onSubmit={handleAddToCartBtnClick}>
          <div className='product-choice-section'>
            
            {
              data.product.data.attributes.woodTypes ?
                <div className='product-choice-inner-container'>
                  {renderChoices(data.product.data.attributes.woodTypes, "woodTypes")}
                </div>
              :
              <></>
            }

            {
              data.product.data.attributes.sizes ?
                <div className='product-choice-inner-container'>
                  {renderChoices(data.product.data.attributes.sizes, "sizes")}
                </div>
              :
              <></>
            }

            {
              data.product.data.attributes.extraChoices ?
                <div className='product-choice-inner-container'>
                  {renderChoices(data.product.data.attributes.extraChoices, "extraChoices")}
                </div>
              :
              <></>
            }
            
          </div>

          <div className='product_detail-content-container'>
            <h1 className='product_detail-name'>{data.product.data.attributes.title}</h1>
            <p className='product_detail-desc'>{data.product.data.attributes.description}</p>
            { 
              data.product.data.attributes.madeToOrder ?
                <p className='product_detail-desc'> <span className='important-span'> IMPORTANT!</span> This item is made to order. Please expect to receive your order within 10-14 business days from when the order is placed. Thank you!</p>
              :
                <p className='product_detail-desc'> Shipping Time: 5-7 Business Days</p>
            }
            <p className='product_detail-price'>{((data.product.data.attributes.price) + getTotalChoiceCost(productChoicesCost)).toLocaleString("en-US", {style:"currency", currency:"USD"})}</p>

          </div>

          <div className='product_detail-btns-container'>
            <div className='product_detail-add-minus-btn-container'>
              <button type="button" className='minus-btn' onClick={handleMinusBtnClick}>-</button>
              <p className='product_detail-product-quantity'>{itemCount}</p>
              <button type="button" className='plus-btn' onClick={handlePlusBtnClick}>+</button>
            </div>
            <button type="submit" className='product_detail-add-cart-btn' >add to cart</button>
          </div>

          {
        data.product.data.attributes.thickness && data.product.data.attributes.width && data.product.data.attributes.length ?

          <div className='product_detail-dimensions-container'>
            <h2 className='product_detail-dimensions-title'>Dimensions</h2>

            <div className='product_detail-dimensions-inner-container'>
              <div>
                <p className='product_detail-dimensions-amount'>{data.product.data.attributes.thickness}"</p>
                <p className='product_detail-dimensions-amount'>{data.product.data.attributes.width}"</p>
                <p className='product_detail-dimensions-amount'>{data.product.data.attributes.length}"</p>
              </div>
              <div>
                <p className='product_detail-dimensions-text'> Product Thickness </p>
                <p className='product_detail-dimensions-text'> Product Width </p>
                <p className='product_detail-dimensions-text'> Product Length </p>
              </div>
            </div>
          
          </div>
        :
        
        <></>
      }

        </form>
      </div>
    
      {
        relatedProductElements[0] !== undefined
        ?
          <div className='product_detail-related-products-container'>
            <h2 className='product_detail-related-products-text'>You may also like</h2>
            <div className='product_detail-related-products'>
              {relatedProductElements}
            </div>
          </div>
        :
          <></>
      }

      <About />
    </div>
  )
}
